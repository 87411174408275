<template>
  <div>
    <v-dialog
      v-model="isDialogVisible"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ 'Preview: ' + device.id_imei }}</span>
        </v-card-title>
        <loading v-if="loading" />
        <v-card-text>
          <div
            id="canvas-container"
            :style="{'overflow-x': !loading ? 'scroll' : null }"
          >
            <canvas
              id="canvas"
              height="0"
            >
            </canvas>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeModal"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, onMounted } from '@vue/composition-api'
import axios from '@axios'
import Loading from '../Loading.vue'

export default {
  name: 'PreviewPanelDialog',
  components: {
    Loading,
  },
  props: {
    device: {
      type: Object,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isDialogVisible = ref(props.show)
    const errorMessages = ref([])
    const chargingPoints = ref([])
    const CELL_WIDTH = 15
    const loading = ref(false)

    const closeModal = () => {
      isDialogVisible.value = false
      emit('update:show', false)
    }

    // const getPrices = () => {
    //   axios.get('/prices')
    //     .then(response => {
    //       console.log(response)
    //       chargingPoints.value = response.data.chargePoints
    //     })
    //     .catch(e => {
    //       console.log(e)
    //     })
    // }

    const preview = () => {
      loading.value = true
      axios.get(`/prices-preview/${props.device.id_imei}`)
        .then(response => {
          loading.value = false
          console.log(response)
          const { data } = response.data
          const canvas = document.getElementById('canvas')
          const ctx = canvas.getContext('2d')
          canvas.style.width = `${CELL_WIDTH * data[0].length}px`
          canvas.style.height = `${CELL_WIDTH * data.length}px`
          canvas.width = canvas.offsetWidth
          canvas.height = canvas.offsetHeight

          let y = 0
          for (let row = 0; row < data.length; row += 1) {
            let x = 0
            for (let col = 0; col < data[0].length; col += 1) {
              ctx.beginPath()
              ctx.rect(x, y, CELL_WIDTH, CELL_WIDTH)
              if (data[row][col] === 1) {
                ctx.fillStyle = '#55e'
                ctx.fill()
              }
              ctx.stroke()
              x += CELL_WIDTH
            }
            y += CELL_WIDTH
          }
        })
        .catch(error => {
          console.log(error)
          console.log('error :>> ', error.response)
          errorMessages.value = error.response.data.error
          loading.value = false
        })
    }

    onMounted(() => {
      if (props.device !== null) {
        preview()
        // getPrices()
      }
    })

    return {
      isDialogVisible,
      closeModal,
      errorMessages,
      chargingPoints,
      loading,
    }
  },
}
</script>
<style>
#canvas-container {
    position: relative;
}

</style>
