<template>
  <div class="my-3">
    <pricing-table
      ref="devicesTable"
      :search="search"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import PricingTable from './components/devices/PricingTable.vue'

export default {
  components: {
    PricingTable,
  },
  setup() {
    const role = localStorage.getItem('userAbility')
    const search = ref('')
    const showModal = ref(false)

    return {
      icons: {
        mdiMagnify,
      },
      role,
      search,
      showModal,
    }
  },
}
</script>
